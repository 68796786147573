/*
	MODULES
		Thumbnail
 */
.thumb {
	display: block;
	position: relative;
	overflow: hidden;
}
.thumb__overlay {
	color: $white;
	background: $blue;
	background: $blueFade;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all $speed $easing;
	will-change: opacity;

	.no-touchevents .thumb:hover &,
	.touchevents .thumb:active & {
		opacity: 1;
	}
}
.thumb__overlay-icon {
	position: absolute;
	right: 43%;
	left: 43%;
	top: 0;
	width: 14%;
	height: 100%;
	transition: all ($speed * 1.5) $easing;
	transform: translateY(2em);
	will-change: transform;

	.no-touchevents .thumb:hover &,
	.touchevents .thumb:active & {
		transform: translateY(0);
	}
}
.thumb__img {
	float: left;

	@media #{$xs} {
		width: 100%;
	}
}