/*
	MODULES
		Callout
 */
.callout {
	color: $white;
	min-height: 36.3rem;
	position: relative;
	overflow: hidden;
	
	@media #{$md} {
		color: $grey;
	}
}
.callout__bg {
	background: no-repeat 50% 50%;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 0;

	@media #{$md} {
		height: 36.3rem;
		position: static;
		transform: initial !important;
	}
}
.callout__inner {
	margin: 0 map-get($gutters, medium);
	position: relative;
	z-index: 1;

	@media #{$md} {
		margin: 0;
	}
}