/*
	MODULES
		Fancybox overrides
 */
.fancybox-overlay {
	background: $blueFade;
	z-index: 1000;
}
.fancybox-close {
	color: $blue;
	background: $white;
	// background: $white url(../img/close.svg) no-repeat 50% 50%;
	// background-size: 1.75em 1.75em;
	width: 3.335em;
	height: 3.335em;
	top: 0;
	right: 0;

	.no-touchevents &:hover,
	.touchevents &:active {
		opacity: 0.75;
	}
}
.fancybox-close::after {
	content: url(../img/close.svg);
	margin: 0.8em;
	width: 1.75em;
	height: 1.75em;
	display: block;
}
#fancybox-loading {
	margin-top: -45px;
	margin-left: -45px;
	opacity: 1;
}
#fancybox-loading div {
	background: $white url('../img/loader.gif') no-repeat 50% 50%;
	background-size: 50px 50px;
	width: 90px;
	height: 90px;
}
.fancybox-nav span {
	top: 50%;
	width: 2em;
	height: 3.65em;
	margin-top: -1.825em;

	.no-touchevents &:hover,
	.touchevents &:active {
		opacity: 0.75;
	}
}
.fancybox-prev span {
	background: url(../img/arrow-left.svg) no-repeat 50% 50%;
}
.fancybox-next span {
	background: url(../img/arrow-right.svg) no-repeat 50% 50%;
}