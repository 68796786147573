/*
	MODULES
		Bloc
 */
.bloc {
	color: $white;
	text-align: center;
	padding: map-get($gutters, small);
	width: 100%;
}
.bloc__icon {
	color: $blue;
	height: 7em;
	margin: 0 0 map-get($gutters, small);
}