/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("../img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("../img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/*
	COMPONENTS
		Wordpress
*/
/* Images */
img.alignnone,
.wp-caption.alignnone,
img.alignleft,
.wp-caption.alignleft {
  float: left;
  margin: 0 1.1em 1.1em 0; }

img.alignright,
.wp-caption.alignright {
  float: right;
  margin: 0 0 1.1em 1.1em; }

img.aligncenter,
.wp-caption.aligncenter {
  display: block;
  margin: 0 auto 1.1em; }

.wp-caption-text {
  font-size: 0.75em;
  margin: 1.45em 0; }

[id^="attachment_"] {
  width: auto !important; }

/*
	MODULES
		Button
 */
.btn {
  border: 0 none;
  text-transform: uppercase;
  padding: 0.75em 1.05em;
  margin: 0 0 1.4em;
  display: inline-block;
  transition: all 0.15s ease-out; }
  .btn:last-child {
    margin: 0; }

/* Blue */
.btn--blue {
  color: #ffffff;
  background: #0d77b7;
  border: 2px solid #0d77b7; }
  .no-touchevents .btn--blue:hover,
  .touchevents .btn--blue:active {
    background: #2a3a8c;
    border-color: #2a3a8c; }

/* White border */
.btn--white-border {
  color: #ffffff;
  border: 2px solid; }
  .no-touchevents .btn--white-border:hover,
  .touchevents .btn--white-border:active {
    background: #2a3a8c;
    border-color: #2a3a8c; }

/* Blue border (md) */
@media screen and (max-width: 960px) {
  .btn--blue-border-md {
    color: #0d77b7;
    border: 2px solid; }
    .no-touchevents .btn--blue-border-md:hover,
    .touchevents .btn--blue-border-md:active {
      color: #ffffff;
      background: #2a3a8c;
      border-color: #2a3a8c; } }

/*
	MODULES
		Bloc
 */
.bloc {
  color: #ffffff;
  text-align: center;
  padding: 3.33rem;
  width: 100%; }

.bloc__icon {
  color: #0d77b7;
  height: 7em;
  margin: 0 0 3.33rem; }

/*
	MODULES
		Thumbnail
 */
.thumb {
  display: block;
  position: relative;
  overflow: hidden; }

.thumb__overlay {
  color: #ffffff;
  background: #0d77b7;
  background: rgba(13, 119, 183, 0.75);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.15s ease-out;
  will-change: opacity; }
  .no-touchevents .thumb:hover .thumb__overlay,
  .touchevents .thumb:active .thumb__overlay {
    opacity: 1; }

.thumb__overlay-icon {
  position: absolute;
  right: 43%;
  left: 43%;
  top: 0;
  width: 14%;
  height: 100%;
  transition: all 0.225s ease-out;
  transform: translateY(2em);
  will-change: transform; }
  .no-touchevents .thumb:hover .thumb__overlay-icon,
  .touchevents .thumb:active .thumb__overlay-icon {
    transform: translateY(0); }

.thumb__img {
  float: left; }
  @media screen and (max-width: 560px) {
    .thumb__img {
      width: 100%; } }

/*
	MODULES
		Fancybox overrides
 */
.fancybox-overlay {
  background: rgba(13, 119, 183, 0.75);
  z-index: 1000; }

.fancybox-close {
  color: #0d77b7;
  background: #ffffff;
  width: 3.335em;
  height: 3.335em;
  top: 0;
  right: 0; }
  .no-touchevents .fancybox-close:hover,
  .touchevents .fancybox-close:active {
    opacity: 0.75; }

.fancybox-close::after {
  content: url(../img/close.svg);
  margin: 0.8em;
  width: 1.75em;
  height: 1.75em;
  display: block; }

#fancybox-loading {
  margin-top: -45px;
  margin-left: -45px;
  opacity: 1; }

#fancybox-loading div {
  background: #ffffff url("../img/loader.gif") no-repeat 50% 50%;
  background-size: 50px 50px;
  width: 90px;
  height: 90px; }

.fancybox-nav span {
  top: 50%;
  width: 2em;
  height: 3.65em;
  margin-top: -1.825em; }
  .no-touchevents .fancybox-nav span:hover,
  .touchevents .fancybox-nav span:active {
    opacity: 0.75; }

.fancybox-prev span {
  background: url(../img/arrow-left.svg) no-repeat 50% 50%; }

.fancybox-next span {
  background: url(../img/arrow-right.svg) no-repeat 50% 50%; }

/*
	MODULES
		Map
 */
.map {
  background: #58585a;
  height: 30.925rem;
  max-height: 75vh; }
  @media screen and (max-width: 1500px) {
    .map {
      height: 45.025rem; } }
  @media screen and (max-width: 960px) {
    .map {
      height: 36.525rem; } }
  @media screen and (max-width: 760px) {
    .map {
      height: 33.725rem; } }
  @media screen and (max-width: 560px) {
    .map {
      height: 33.425rem; } }

.map img {
  max-width: none;
  max-height: none; }

/*
	MODULES
		Callout
 */
.callout {
  color: #ffffff;
  min-height: 36.3rem;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 960px) {
    .callout {
      color: #58585a; } }

.callout__bg {
  background: no-repeat 50% 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0; }
  @media screen and (max-width: 960px) {
    .callout__bg {
      height: 36.3rem;
      position: static;
      transform: initial !important; } }

.callout__inner {
  margin: 0 6.66rem;
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 960px) {
    .callout__inner {
      margin: 0; } }

/*
	MODULES
		Certification
 */
.certif {
  margin: -3rem 0 0; }
  @media screen and (max-width: 960px) {
    .certif {
      margin: -3rem 0 0; } }
  @media screen and (max-width: 760px) {
    .certif {
      margin: 3.33rem 0; } }

.certif__img {
  max-width: 40%;
  margin: 0 4%;
  display: inline-block; }
  @media screen and (max-width: 760px) {
    .certif__img {
      margin: 0 4% 0 0; } }

/*
	MODULES
		Credit
 */
.no-touchevents .credit:hover,
.touchevents .credit:active {
  color: #73d3f4; }

.credit__logo {
  height: 1.1em;
  width: 4.6em;
  position: relative;
  top: 0.05em; }

/*
	UTILITIES
		Layout
*/
/* Flexbox */
.flexbox .u-flex {
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap; }

.flexbox .u-flex--center {
  align-items: center;
  justify-content: center; }

/* Misc */
.u-no-margin {
  margin: 0 !important; }

.u-clear {
  clear: both !important;
  width: 100% !important; }

.u-pull-right {
  float: right !important; }

.u-pull-left {
  float: left !important; }

@media screen and (max-width: 760px) {
  .u-float-none-sm {
    float: none !important; } }

.u-offset-parent {
  position: relative; }

.u-overflow-visible {
  overflow: visible !important; }

/* Visible / Hidden */
.u-hidden-xl {
  display: none !important; }

.u-visible-xl {
  display: block !important; }

@media screen and (max-width: 1200px) {
  .u-hidden-lg {
    display: none !important; }
  .u-visible-lg {
    display: block !important; } }

@media screen and (max-width: 960px) {
  .u-hidden-md {
    display: none !important; }
  .u-visible-md {
    display: block !important; } }

@media screen and (max-width: 760px) {
  .u-hidden-sm {
    display: none !important; }
  .u-visible-sm {
    display: block !important; } }

@media screen and (max-width: 560px) {
  .u-hidden-xs {
    display: none !important; }
  .u-visible-xs {
    display: block !important; } }

/*
	UTILITIES
		Typography
 */
.u-txt-center {
  text-align: center !important; }

.u-txt-right {
  text-align: right !important; }

.u-txt-left {
  text-align: left !important; }

@media screen and (max-width: 760px) {
  .u-txt-left-sm {
    text-align: left !important; } }

.u-txt-large {
  font-size: 1.35em !important;
  margin: 0 0 0.9em !important; }

.u-txt-upper {
  text-transform: uppercase !important; }

/*
	UTILITIES
		Color
 */
.u-bg-light-cyan {
  background-color: #73d3f4 !important; }

.u-bg-cyan {
  background-color: #1babdd !important; }

.u-bg-purple {
  background-color: #2a3a8c !important; }

.u-bg-dark-purple {
  background-color: #28235e !important; }

/*
	UTILITIES
		Animations
 */
@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.u-rotating {
  animation: rotating 2s linear infinite; }

.u-transition {
  transition: all 0.15s ease-out; }
