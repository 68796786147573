//	MIXINS
//		Colors
$white: #ffffff;
$whiteFade: rgba(255, 255, 255, 0.9);
$grey: #58585a;
$blue: #0d77b7;
$blueFade: rgba(13, 119, 183, 0.75);
$lightCyan: #73d3f4;
$cyan: #1babdd;
$purple: #2a3a8c;
$darkPurple: #28235e;
$red: #5E2339;