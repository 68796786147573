/*
	UTILITIES
		Animations
 */
@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.u-rotating {
	animation: rotating 2s linear infinite;
}
.u-transition {
	transition: all $speed $easing;
}