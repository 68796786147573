/*
	MODULES
		Map
 */
.map {
	background: $grey;
	height: 30.925rem;
	max-height: 75vh;

	@media screen and (max-width: 1500px) {
		height: 45.025rem;
	}
	@media #{$md} {
		height: 36.525rem;
	}
	@media #{$sm} {
		height: 33.725rem;
	}
	@media #{$xs} {
		height: 33.425rem;
	}
}
.map img {
	max-width: none;
	max-height: none;
}