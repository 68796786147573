/*
	MODULES
		Button
 */
.btn {
	border: 0 none;
	text-transform: uppercase;
	padding: 0.75em 1.05em;
	margin: 0 0 (map-get($gutters, txt));
	display: inline-block;
	transition: all $speed $easing;

	&:last-child {
		margin: 0;
	}
}
/* Blue */
.btn--blue {
	color: $white;
	background: $blue;
	border: 2px solid $blue;

	.no-touchevents &:hover,
	.touchevents &:active {
		background: $purple;
		border-color: $purple;
	}
}
/* White border */
.btn--white-border {
	color: $white;
	border: 2px solid;

	.no-touchevents &:hover,
	.touchevents &:active {
		background: $purple;
		border-color: $purple;
	}
}
/* Blue border (md) */
@media #{$md} {
	.btn--blue-border-md {
		color: $blue;
		border: 2px solid;

		.no-touchevents &:hover,
		.touchevents &:active {
			color: $white;
			background: $purple;
			border-color: $purple;
		}
	}
}