/*
	MODULES
		Certification
 */
.certif {
	margin: -3rem 0 0;

	@media #{$md} {
		margin: -3rem 0 0;
	}
	@media #{$sm} {
		margin: (map-get($gutters, small)) 0;
	}
}
.certif__img {
	max-width: 40%;
	margin: 0 4%;
	display: inline-block;
	
	@media #{$sm} {
		margin: 0 4% 0 0;
	}
}