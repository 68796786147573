/*
	UTILITIES
		Color
 */
.u-bg-light-cyan {
	background-color: $lightCyan !important;
}
.u-bg-cyan {
	background-color: $cyan !important;
}
.u-bg-purple {
	background-color: $purple !important;
}
.u-bg-dark-purple {
	background-color: $darkPurple !important;
}