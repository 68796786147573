/*
	UTILITIES
		Typography
 */
.u-txt-center {
	text-align: center !important;
}
.u-txt-right {
	text-align: right !important;
}
.u-txt-left {
	text-align: left !important;
}
.u-txt-left-sm {
	@media #{$sm} {
		text-align: left !important;
	}
}
.u-txt-large {
	font-size: 1.35em !important;
	margin: 0 0 0.9em !important;
}
.u-txt-upper {
	text-transform: uppercase !important;
}