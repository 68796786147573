/*
	MODULES
		Credit
 */
.credit {

	.no-touchevents &:hover,
	.touchevents &:active {
		color: $lightCyan;
	}
}
.credit__logo {
	height: 1.1em;
	width: 4.6em;
	position: relative;
	top: 0.05em;
}